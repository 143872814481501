import { ApolloProvider } from '@apollo/client';
import { NextUIProvider } from '@nextui-org/react';
import { Auth } from 'aws-amplify';
import { AnimatePresence } from 'framer-motion';
import { lazy, Suspense, useEffect, useMemo, useState } from 'react';
import { toast, Toaster } from 'react-hot-toast';
import lazyWithPreload from 'react-lazy-with-preload';
import { BrowserRouter as Router, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { Root } from './App.styles';
import { InfoIcon } from './assets';
import { axiosClient } from './axios';
import { LinearLoading } from './components';
import { AuthenticatedRoute, UnauthenticatedRoute } from './components';
import { HOME_PATH, ROUTES } from './constants';
import { useAuthContext } from './contexts/AuthContext';
import { SessionProvider } from './contexts/SessionContext';
import { createClient } from './graphql';
import { AppContext, useSessionStorage } from './hooks';
import { GlobalStyles } from './theme/GlobalStyles';
import { darkTheme, lightTheme, nextUIThemeMap } from './theme/Themes';
import { configureAmplify } from './utils/Amplify';

configureAmplify();

const LoginModule = lazyWithPreload(() => import('./views/auth/LoginPage'));
const AppModule = lazy(() => import('./views/app-container'));

function App() {
  const [isAuthenticated, userHasAuthenticated] = useSessionStorage('user.authenticated.admin', false);
  const [error, setError] = useSessionStorage('page.error.admin', '');
  const [loading, setLoading] = useState(true);
  const [themeName, setThemeName] = useState<'light' | 'dark'>('light');

  const theme = themeName === 'dark' ? darkTheme : lightTheme;

  const showError = (error: string, code?: string) => {
    if (code === 'UNAUTHENTICATED') {
      if (isAuthenticated) {
        // trigger a logout
        void reloadAuthInfo();
      } else {
        // don't show a toast if unauthenticated and there's an unauthenticated error
        return;
      }
    }
    toast.error(error);
  };

  const showAlert = (alert: string) => {
    toast(alert, { icon: <InfoIcon /> });
  };

  const { authToken, updateAuthToken, checkUserAuthorization } = useAuthContext();

  const preloadUnauthModules = () => {
    void LoginModule.preload();
  };

  const logout = async () => {
    setLoading(true);
    userHasAuthenticated(false);
    try {
      await Promise.allSettled([
        axiosClient.get('/dlrSecured/logout', {
          headers: {
            Authorization: authToken,
          },
        }),
        client.clearStore(),
        Auth.signOut(),
      ]);
    } catch (err: any) {
      console.error({
        message: 'Error while logging out',
        error: err?.stack || err,
      });
    } finally {
      localStorage.clear();
      setLoading(false);
    }
  };

  const reloadAuthInfo = async (): Promise<void> => {
    setLoading(true);
    const hasActiveUserToken = await updateAuthToken();
    const isAuthorizedUser = hasActiveUserToken && (await checkUserAuthorization());

    const unauthorizedUser = hasActiveUserToken && !isAuthorizedUser;
    const authorizedUser = isAuthorizedUser && hasActiveUserToken;
    const noUser = !hasActiveUserToken && !isAuthenticated;

    if (noUser) {
      preloadUnauthModules();
    } else if (unauthorizedUser) {
      setError('Invalid user');
      preloadUnauthModules();
      await logout();
    } else if (authorizedUser) {
      userHasAuthenticated(true);
    }

    setLoading(false);
  };

  useEffect((): void => {
    void reloadAuthInfo();
    if (error) {
      showError(error);
      setError('');
    }
  }, []);

  const Main = () => {
    return isAuthenticated ? <Navigate replace to={HOME_PATH} /> : <Navigate replace to="/login" />;
  };

  const AnimatedRoutes = () => {
    const location = useLocation();

    const contextValues = {
      isAuthenticated,
      reloadAuthInfo,
      showError,
      showAlert,
      themeName,
      setThemeName,
      logout,
      location,
    };

    return (
      <AppContext.Provider value={contextValues}>
        <AnimatePresence initial={false} exitBeforeEnter={true}>
          <Routes location={location} key={location.pathname}>
            <Route path="/*" element={<Main />} />
            <Route element={<UnauthenticatedRoute />}>
              <Route path={ROUTES.Login} element={<LoginModule />} />
            </Route>
            <Route element={<AuthenticatedRoute />}>
              <Route path={ROUTES.Root} element={<AppModule />} />
            </Route>
          </Routes>
        </AnimatePresence>
      </AppContext.Provider>
    );
  };

  const client = useMemo(() => createClient(authToken, showError), [authToken]);

  const animation = <LinearLoading />;

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <NextUIProvider theme={nextUIThemeMap(theme)}>
          <>
            <GlobalStyles theme={theme} />
            {loading ? (
              animation
            ) : (
              <SessionProvider>
                <Router>
                  <Suspense fallback={animation}>
                    <Root>
                      <AnimatedRoutes />
                    </Root>
                  </Suspense>
                </Router>
              </SessionProvider>
            )}
          </>
          <Toaster containerStyle={{ zIndex: 10000 }} />
        </NextUIProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
}

export default App;
