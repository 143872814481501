import { QuestionnaireContainer, QuestionnaireSection } from './CollapsibleQuestionnaire.styles';
import { QuestionnaireItem } from './QuestionnaireItem';
import { Question, QuestionAnswer, QuestionOption } from './types';

interface CollapsibleQuestionnaireSections {
  title: string;
  questions: Array<Question>;
}

interface CollapsibleQuestionnnaireProps {
  readOnly?: boolean;
  sections: Array<CollapsibleQuestionnaireSections>;
  onChange?: (code: string, answer: QuestionAnswer) => void;
}

export const CollapsibleQuestionnaire = ({ sections, readOnly, onChange }: CollapsibleQuestionnnaireProps) => {
  return (
    <QuestionnaireContainer>
      {sections.map(({ questions, title }) => (
        <QuestionnaireSection title={title}>
          {questions.map((question) => (
            <QuestionnaireItem
              onNotesChange={(notes: string) => onChange(question.code, { ...question.answer, otherText: notes })}
              onAnswerChange={(option: QuestionOption) => onChange(question.code, { ...question.answer, ...option })}
              readOnly={readOnly}
              enableAdditionalNotes={question?.enableAdditionalNotes ?? true}
              question={question}
            />
          ))}
        </QuestionnaireSection>
      ))}
    </QuestionnaireContainer>
  );
};
