import { Progress } from '@nextui-org/react';
import styled from 'styled-components';

const TopProgress = styled(Progress)`
  padding: 0;
  border-radius: 0;
  height: 0.5rem;
`;

export default TopProgress;
