import { Auth } from 'aws-amplify';
import React, { createContext, FC, ReactNode, useState } from 'react';

type Nullable<T> = T | null;

export interface AuthContextProps {
  authToken: Nullable<string>;
  updateAuthToken: () => Promise<boolean>;
  checkUserAuthorization: () => Promise<boolean>;
}

interface AuthProviderProps {
  children: ReactNode;
}

/* eslint-disable */
const defaultProps: AuthContextProps = {
  authToken: null,
  updateAuthToken: async () => false,
  checkUserAuthorization: async () => false,
};
/* eslint-enable */

const AuthContext = createContext<AuthContextProps>(defaultProps);

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [authToken, setAuthToken] = useState<Nullable<string>>(null);

  /**
   * Attempts to get a new JWT token for the current session.
   * @returns {boolean} indicating if the token was successfully updated
   */
  const updateAuthToken = async (): Promise<boolean> => {
    try {
      const token = (await Auth.currentSession()).getIdToken().getJwtToken();
      setAuthToken(token);
      return true;
    } catch (err: any) {
      if (err !== 'No current user') {
        alert(err);
        console.error({
          message: 'Error while updating auth token',
          error: err?.stack || err,
        });
      }
      setAuthToken(null);
    }
    return false;
  };

  /**
   * Checks if the current session has the admin scope variables
   * @returns boolean isAdmin indicating the user scope
   */
  const checkUserAuthorization = async (): Promise<boolean> => {
    let isAdmin = false;

    try {
      const userScope = (await Auth.currentSession()).getIdToken().decodePayload()['custom:scope'];
      isAdmin = userScope.includes('admin:read') || userScope.includes('admin:write');
    } catch (err: any) {
      console.error({
        message: 'Error while authorizing',
        error: err?.stack || err,
      });
    }

    return isAdmin;
  };

  return (
    <AuthContext.Provider
      value={{
        authToken,
        updateAuthToken,
        checkUserAuthorization,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext: () => AuthContextProps = () => {
  return React.useContext(AuthContext);
};
