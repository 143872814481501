import {
  Header as SharedHeader,
  LogoutIcon,
  PopoverButton,
  PopoverPlacement,
  ProtonLogoSimple,
} from '@trarc/pc-shared';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PATHS } from '../../constants';
import { useSessionContext } from '../../contexts/SessionContext';
import { useAppContext } from '../../hooks';
import { LogoContainer, StyledChevronIcon, UserActionDropdownRow, UserInfo, UserName } from './Header.styles';

export const Header = () => {
  const { me } = useSessionContext();
  const { logout } = useAppContext();
  const { t } = useTranslation();
  const [isPopoverOpen, setPopoverOpen] = useState(false);

  return (
    <SharedHeader
      logo={
        <LogoContainer>
          <ProtonLogoSimple />
          <span>Admin</span>
        </LogoContainer>
      }
      logoPath={PATHS.ROOT}
      links={[
        { to: PATHS.Dealerships.ROOT, label: t('dealerships.link') },
        { to: PATHS.RiskAssessmentDocuments.ROOT, label: t('riskAssessments.link') },
        { to: PATHS.ApplicationUsers.ROOT, label: t('users.link') },
        { to: PATHS.Vendors.ROOT, label: t('vendors.link') },
        { to: PATHS.VendorRequests.ROOT, label: t('vendorRequests.link') },
        { to: PATHS.Documents.ROOT, label: t('documents.link') },
        { to: PATHS.VendorRisks.ROOT, label: t('vendorRisks.link') },
        { to: PATHS.ThirdPartyApi.ROOT, label: t('thirdPartyApi.link') },
        { to: PATHS.AffiliateGroups.ROOT, label: t('affiliateGroup.link') },
      ]}
      rightContent={
        me && (
          <PopoverButton
            isOpen={isPopoverOpen}
            onOpenChange={setPopoverOpen}
            placement={PopoverPlacement.BottomRight}
            triggerDisplay={
              <UserInfo>
                <UserName>{`${me?.firstName} ${me?.lastName}`}</UserName>
                <StyledChevronIcon />
              </UserInfo>
            }
          >
            <UserActionDropdownRow
              onClick={logout}
              children={
                <>
                  <LogoutIcon stroke="black" />
                  <span>{t('profile.logout')}</span>
                </>
              }
            />
          </PopoverButton>
        )
      }
    />
  );
};
