import { useUpdateState } from '@trarc/pc-shared';

export const useEditorState = <EditorValues>(
  initialState: EditorValues,
  isStateAltered: (initialState: EditorValues, currState: EditorValues) => boolean,
) => {
  const [editorState, updateEditorState, setEditorState] = useUpdateState(initialState);

  const resetState = () => {
    setEditorState(initialState);
  };

  const isAltered = isStateAltered(initialState, editorState);

  return [editorState, updateEditorState, resetState, isAltered, setEditorState] as const;
};
