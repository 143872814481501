import 'react-datepicker/dist/react-datepicker.css';

import { focusedOutline, Icon, Input } from '@trarc/pc-shared';
import styled, { css } from 'styled-components';

import { CalendarIcon } from '../../assets';
import { ThemeProp } from '../../theme/Themes';

export const Container = styled.div`
  display: flex;
  position: relative;
`;

export const StyledInput = styled(Input)`
  ${Icon} {
    width: 1.25rem;
  }
  input {
    margin: 0;
  }
`;

export const StyledCalendarIcon = styled(CalendarIcon)`
  stroke: ${(props: ThemeProp) => props.theme.colors.c400};
  width: 1.375rem;
  height: 1.375rem;
`;

const selectedDayStyles = css`
  background-color: ${(props: ThemeProp) => props.theme.colors.primary};
  color: ${(props: ThemeProp) => props.theme.colors.white};
`;

export const DatePickerContainer = styled.div`
  position: absolute;
  top: 100%;
  z-index: 400;

  & .react-datepicker {
    font-family: ${(props: ThemeProp) => props.theme.fonts.primary};
    box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
    border-color: ${(props: ThemeProp) => props.theme.colors.c250};
    padding: 1rem;

    &__year-select {
      border: none;
      background: none;

      &:focus-visible {
        border-radius: 0.3rem;
        ${focusedOutline}
      }
    }

    &__header {
      background-color: ${(props: ThemeProp) => props.theme.colors.white};
      border-top-right-radius: 0.5rem;
      border-top-left-radius: 0.5rem;
      border-bottom: none;
      padding: 0.5rem 0 0;
    }

    &__day-name {
      color: ${(props: ThemeProp) => props.theme.colors.c400};
    }

    &__current-month {
      color: ${(props: ThemeProp) => props.theme.colors.c500};
      font-weight: ${(props: ThemeProp) => props.theme.fontWeight.semibold};
      text-transform: uppercase;
    }

    &__month {
      border: 2px solid ${(props: ThemeProp) => props.theme.colors.c100};
      margin: 0.4rem 0;
    }

    &__week {
      border-top: 2px solid ${(props: ThemeProp) => props.theme.colors.c100};

      .react-datepicker__day {
        &:last-of-type {
          border-right: none;
        }
      }
    }

    &__day {
      border-right: 2px solid ${(props: ThemeProp) => props.theme.colors.c100};
      width: ${36 / 16}rem;
      height: ${36 / 16}rem;
      margin: 0;
      font-size: ${12 / 16}rem;
      line-height: ${15.6 / 16}rem;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 0;
      color: ${(props: ThemeProp) => props.theme.colors.c500};

      &:hover {
        background-color: ${(props: ThemeProp) => props.theme.colors.c150};
      }

      &:focus-visible {
        border-radius: 0.3rem;
        ${focusedOutline}
      }

      &--selected {
        ${selectedDayStyles}
        &:hover {
          background-color: ${(props: ThemeProp) => props.theme.colors.primary}54;
        }
      }

      &--today {
        background-color: ${(props: ThemeProp) => props.theme.colors.primary}21;
        color: ${(props: ThemeProp) => props.theme.colors.c500};

        &:hover {
          background-color: ${(props: ThemeProp) => props.theme.colors.c150};
        }

        &.react-datepicker__day--selected {
          ${selectedDayStyles}
        }
      }
    }

    &__day-name {
      width: ${36 / 16}rem;
      height: ${36 / 16}rem;
      font-size: ${12 / 16}rem;
      line-height: ${15.6 / 16}rem;
      margin: 0;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }

    &__navigation {
      height: 1.25rem;
      width: 1.25rem;

      &:focus-visible {
        border-radius: 0.3rem;
        ${focusedOutline}
      }

      &--previous,
      &--next {
        top: 1.5rem;
      }

      &--previous {
        left: 1rem;
      }

      &--next {
        right: 1rem;
      }

      &--years-upcoming,
      &--years-previous {
        position: static;
        background: url('data:image/svg+xml,<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M7 9.5L12 14.5L17 9.5" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" /></svg>');
      }

      &--years-upcoming {
        transform: scaleY(-1);
      }

      &-icon {
        &::before {
          border-width: 2px 2px 0 0;
          border-color: ${(props: ThemeProp) => props.theme.colors.c400};
          left: 50%;
          top: 50%;
        }

        &--previous {
          &::before {
            transform: translate(-50%, -50%) rotate(225deg);
          }
        }

        &--next {
          &::before {
            transform: translate(-50%, -50%) rotate(45deg);
          }
        }
      }
    }
  }
`;
