import { Button, ThemeProp } from '@trarc/pc-shared';
import styled from 'styled-components';

export const AssigneeSearchSelectContainer = styled.div``;

export const AssigneeInputContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: flex-end;
`;

export const AssigneeContainer = styled.div`
  position: relative;
  border: 1px solid ${(props: ThemeProp) => props.theme.colors.c200};
  border-radius: ${(props: ThemeProp) => props.theme.borderRadius};
  padding: 0.5rem;
  display: flex;
  justify-content: center;
`;

export const AssigneeCancelButton = styled(Button)`
  position: absolute;
  padding: 0;
  width: 1rem;
  height: 1rem;
  right: 0.75rem;
  top: 0.75rem;
`;
