import { ButtonRole, SearchSingleSelect } from '@trarc/pc-shared';
import { t } from 'i18next';
import { useState } from 'react';

import { StyledCloseIcon } from '../CommonIcons';
import {
  AssigneeCancelButton,
  AssigneeContainer,
  AssigneeInputContainer,
  AssigneeSearchSelectContainer,
} from './AssigneeSearchSelect.styles';
import { userListLimit } from './constants';

interface AssigneeSearchSelectProps {
  results: { value: string; label: string }[];
  totalCount: number;
  selectedAssignee: string;
  firstNameFilter: string;
  setFirstNameFilter: (firstName: string) => void;
  lastNameFilter: string;
  setLastNameFilter: (lastName: string) => void;
  updateAssignee: (assigneeId: string, label: string) => void;
  showCancelIcon: boolean;
  onCancel: () => void;
  className?: string;
}

export const AssigneeSearchSelect = ({
  results,
  totalCount,
  selectedAssignee,
  firstNameFilter,
  setFirstNameFilter,
  lastNameFilter,
  setLastNameFilter,
  updateAssignee,
  showCancelIcon,
  onCancel,
  className,
}: AssigneeSearchSelectProps) => {
  const [isFirstNameDropdownOpen, setIsFirstNameDropdownOpen] = useState(false);
  const [isLastNameDropdownOpen, setIsLastNameDropdownOpen] = useState(false);

  return (
    <AssigneeSearchSelectContainer className={className}>
      <AssigneeInputContainer>
        <SearchSingleSelect
          open={isFirstNameDropdownOpen}
          inputProps={{
            onChange: (e) => setFirstNameFilter(e.target.value),
            value: firstNameFilter || '',
            onFocus: () => setIsFirstNameDropdownOpen(true),
            placeholder: t('tasks.task.assigneeSelect.placeholderFirstName'),
          }}
          onBlur={() => {
            setIsFirstNameDropdownOpen(false);
          }}
          searchSelectTitle={t('tasks.task.assigneeSelect.label')}
          results={results}
          onCancel={() => {
            setIsFirstNameDropdownOpen(false);
          }}
          onSelect={(selectedItem) => {
            setIsFirstNameDropdownOpen(false);
            updateAssignee(selectedItem.value, selectedItem.label);
          }}
          searchResultLabel={
            totalCount > userListLimit
              ? t('tasks.task.assigneeSelect.topXSearchResults', {
                  count: userListLimit,
                })
              : t('tasks.task.assigneeSelect.xSearchResults', {
                  count: totalCount,
                })
          }
          searchLabel={t('tasks.task.assigneeSelect.label')}
        />
        <SearchSingleSelect
          open={isLastNameDropdownOpen}
          inputProps={{
            onChange: (e) => setLastNameFilter(e.target.value),
            value: lastNameFilter || '',
            onFocus: () => setIsLastNameDropdownOpen(true),
            placeholder: t('tasks.task.assigneeSelect.placeholderLastName'),
          }}
          onBlur={() => {
            setIsLastNameDropdownOpen(false);
          }}
          results={results}
          onCancel={() => {
            setIsLastNameDropdownOpen(false);
          }}
          onSelect={(selectedItem) => {
            setIsLastNameDropdownOpen(false);
            updateAssignee(selectedItem.value, selectedItem.label);
          }}
          searchResultLabel={
            totalCount > userListLimit
              ? t('tasks.task.assigneeSelect.topXSearchResults', {
                  count: userListLimit,
                })
              : t('tasks.task.assigneeSelect.xSearchResults', {
                  count: totalCount,
                })
          }
          searchLabel={t('tasks.task.assigneeSelect.label')}
        />
      </AssigneeInputContainer>
      <AssigneeContainer>
        {selectedAssignee}
        {showCancelIcon && (
          <AssigneeCancelButton buttonRole={ButtonRole.IconOnly} icon={<StyledCloseIcon />} onClick={onCancel} />
        )}
      </AssigneeContainer>
    </AssigneeSearchSelectContainer>
  );
};
